<template lang="html">
  <div>
    <Portal to="main-overlay" :disabled="showModal" slim>
      <b-modal
        :active.sync="showModal"
        :canCancel="['escape', 'outside', 'x']"
      >
        <div class="card has-margin-bottom-sm">
          <div class="create-post-modal-title has-text-centered">Create Post</div>
          <article class="media has-padding-md">
            <figure class="media-left">
              <p class="image is-48x48 is-square">
                <img class="is-rounded" :src="$auth.user.profile.thumbnail" />
              </p>
            </figure>
            <div class="media-content has-padding-top-xxs" style="overflow: hidden;">
              <form class="form">
                <b-field>
                  <div class="control">
                    <textarea
                      ref="textarea"
                      v-model="content"
                      class="textarea is-hovered is-medium is-size-6"
                      placeholder="What's on your mind?"
                      rows="4"
                      autofocus
                      required
                    >
                    </textarea>
                  </div>
                </b-field>

                <div class="columns is-mobile is-multiline">
                  <div
                    class="column is-one-third"
                    v-for="(image, index) in images"
                    :key="image.id"
                  >
                    <a class="delete" @click.prevent="removeImage(image, index)"></a>
                    <p class="image is-square" @click.prevent="rotateImage($event, index)">
                      <img :id="`editImage-${index}`" class="" :src="objectURL(image)" />
                      <b-icon class="is-overlay" style="margin-left: auto; margin-right: auto; top: 40%; z-indez: 1; -webkit-text-stroke: 2px darkslategrey;" icon="rotate-left" type="is-primary" size="is-medium"></b-icon>
                    </p>
                  </div>
                </div>

                <b-field grouped>
                  <b-upload @input="onInput" v-model="images" accept="image/*" multiple>
                    <div class="box has-padding-sm is-marginless">
                      <b-icon icon="image-plus" size="is-medium"> </b-icon>
                    </div>
                  </b-upload>
                </b-field>

                <b-field>
                  <div class="control">
                    <a class="button is-primary is-rounded is-fullwidth" :class="{'is-loading': isLoading}" @click.prevent="createPost">Post</a>
                  </div>
                </b-field>
              </form>
            </div>
          </article>
        </div>
      </b-modal>
    </Portal>

  <div class="card has-margin-bottom-sm">
    <article class="media has-padding-sm has-padding-right-md">
      <figure class="media-left">
        <p class="image is-48x48 is-square">
          <img class="is-rounded" :src="$auth.user.profile.thumbnail" />
        </p>
      </figure>
      <div class="media-content has-padding-top-xxs" style="overflow: hidden;">
        <textarea
          class="textarea is-size-6"
          style="resize: none;"
          placeholder="Have something to say?"
          rows="1"
          @click="onTouchTextarea()"
          readonly
        >
        </textarea>
      </div>
    </article>
  </div>
</div>
</template>

<script>
import { ACTIONS } from "../store/actions.types";
import { MUTATIONS } from "../store/mutations.types";

const { POST_CREATE_POST } = ACTIONS;
const { FEED_ADD_POSTS_START } = MUTATIONS;
import Post from "../models/Post";

export default {
    data() {
        return {
            showModal: false,
            content: "",
            images: [],
            isLoading: false,
            uploadProgression: 0,

            // rotation stuff
            imageSteps: [],
            imageRotations: [],
            rotationMap: {
                0: 0,
                1: 90,
                2: 180,
                3: 270,
            },
        };
    },
    methods: {
        onPasteHandler(e) {
            let files = e.clipboardData.files;
            if (files) {
                for (var i = 0; i < files.length; i++) {
                    this.images.push(files[i]);
                    this.imageSteps.push(0);
                    this.imageRotations.push(0);
                }
            }
        },
        onInput(images) {
            for (var i = 0; i < images.length; i++) {
                this.imageSteps.push(0);
                this.imageRotations.push(0);
            }
            this.$nextTick(() => {
                // closest I can get to a working solution
                this.$scrollTo(".card", 1500, {
                    offset: 9999,
                    container: ".animation-content.modal-content",
                });
            });
        },
        rotateImage(e, index) {
            this.imageSteps[index] = ++this.imageSteps[index] % 4;
            const rotation = -1 * this.rotationMap[this.imageSteps[index]];
            this.imageRotations[index] = rotation;
            const target = document.getElementById(`editImage-${index}`);
            target.style["transform"] = `rotate(${rotation}deg)`;
        },
        images2Blob(images) {
            return images.map((i) => {
                return {
                    name: i.name,
                    blob: new Blob([i], { type: i.type }),
                };
            });
        },
        removeImage(image, index) {
            this.images.splice(index, 1);
            this.imageSteps.splice(index, 1);
            this.imageRotations.splice(index, 1);
        },
        async createPost() {
            this.isLoading = true;
            const form = new FormData();
            form.append("content", this.content);

            const data = this.images2Blob(this.images);

            for (var i = 0; i < data.length; i++) {
                form.append("images", data[i].blob, data[i].name);
                form.append(
                    "rotations",
                    `ROTATE_${Math.abs(this.imageRotations[i]) || 0}`
                );
            }
            try {
                // let config = {
                //     onUploadProgress: (progressEvent) => {
                //         this.uploadProgression = parseInt(
                //             Math.round(
                //                 (progressEvent.loaded / progressEvent.total) *
                //                     100
                //             )
                //         );
                //         console.log(progressEvent.loaded, "loaded");
                //         console.log(progressEvent.total, "total");
                //     },
                // };

                const postData = await this.$axios.$post("api/posts/", form);
                let entries = Post.query()
                    .withAllRecursive()
                    .get();
                entries.unshift(postData);
                Post.create({ data: entries, insert: ["users", "profiles"] });
            } catch (err) {
                console.error("[PostComposer:createPost]: ", err);
            } finally {
                this.content = "";
                this.images = [];
                this.imageSteps = [];
                this.imageRotations = [];
                this.showModal = false;
                this.isLoading = false;
            }
        },
        onTouchTextarea() {
            this.showModal = true;
            this.$nextTick(() => {
                this.$refs.textarea && this.$refs.textarea.focus();
            });
        },
        objectURL(file) {
            return URL.createObjectURL(file);
        },
    },
    watch: {
        showModal(v) {
            if (v) {
                this.$nextTick(() => {
                    this.$refs.textarea &&
                        this.$refs.textarea.addEventListener(
                            "paste",
                            this.onPasteHandler,
                            false
                        );
                });
            } else {
                this.$refs.textarea &&
                    this.$refs.textarea.removeEventListener(
                        "paste",
                        this.onPasteHandler
                    );
            }
        },
    },
};
</script>

<style lang="css" scoped>
::v-deep .animation-content.modal-content {
    position: fixed;
    top: 0;
    margin: 0 !important;
}
.modal .modal-content .card {
    margin-top: 3.25rem;
}

.form {
    overflow: hidden;
}
.create-post-modal-title {
    background-color: #f5f6f7;
    border-bottom: 1px solid #dddfe2;
    border-radius: 2px 2px 0 0;
    font-weight: bold;
}
</style>
