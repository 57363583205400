<template>
    <div v-if="this.$auth.user" class="has-padding-top-none">
        <PostComposer />
        <Feed />
        <nav class="level has-margin-vertical-sm">
            <div v-if="next" class="level-item has-text-centered">
                <a
                    class="button is-fullwidth"
                    :class="{'is-loading': postsLoading}"
                    @click="debounceFetchMorePosts"
                >Load more</a>
            </div>
            <div v-else class="level-item has-text-centered">No new post</div>
        </nav>
    </div>
    <div v-else>
        <section class="hero is-info is-medium is-bold">
            <div class="hero-body has-text-centered">
                <div class="container">
                    <h1 class="title">Connect with Friends</h1>
                    <h2 class="subtitle">Not advertisers</h2>
                    <br />
                    <br />
                    <br />
                    <br />
                    <nuxt-link to="/login">
                        <button class="button is-primary">Login</button>
                    </nuxt-link>
                    <nuxt-link to="/register">
                        <button class="button is-success">Sign Up</button>
                    </nuxt-link>
                </div>
            </div>
        </section>
        <section class="container">
            <div class="columns features has-text-centered">
                <div class="column is-4">
                    <div class="card is-shady">
                        <div class="card-image">
                            <i class="fa fa-paw"></i>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <h4>A safe space for all.</h4>
                                <b-icon
                                    class="is-centered"
                                    icon="eye-off"
                                    size="is-medium"
                                    custom-size="mdi-48px"
                                ></b-icon>
                                <p>No gimmicks, no ads, no bullshirt! Freely speak your mind. We will *NEVER* sell or use your data for advertising</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="card is-shady">
                        <div class="card-image is-centered">
                            <i class="fa fa-empire"></i>
                        </div>
                        <div class="card-content">
                            <nuxt-link to="/communities">
                                <div class="content">
                                    <h4>Discover communities.</h4>
                                    <b-icon
                                        class="has-padding-vertical-md"
                                        icon="account-group"
                                        size="is-medium"
                                        custom-size="mdi-48px"
                                    ></b-icon>
                                    <p>Whatever you're into, there's a community for you.</p>
                                </div>
                            </nuxt-link>
                        </div>
                    </div>
                </div>
                <div class="column is-4">
                    <div class="card is-shady">
                        <div class="card-image has-text-centered">
                            <i class="fa fa-apple"></i>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <h4>Chat</h4>
                                <b-icon
                                    class="has-padding-vertical-md"
                                    icon="forum"
                                    size="is-large"
                                    custom-size="mdi-48px"
                                ></b-icon>
                                <p>Message other users in an 1-1 instant messaging system.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <footer class="footer has-text-centered">
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <h2>
                            <strong>About Us</strong>
                        </h2>
                        <ul>
                            <nuxt-link to="/about">
                                <li>
                                    <a>About</a>
                                </li>
                            </nuxt-link>
                        </ul>
                    </div>
                    <div class="column">
                        <h2>
                            <strong>Privacy</strong>
                        </h2>
                        <ul>
                            <nuxt-link to="/privacy-policy">
                                <li>
                                    <a>Privacy Policy</a>
                                </li>
                            </nuxt-link>
                            <nuxt-link to="/content-policy">
                                <li>
                                    <a>Content Policy</a>
                                </li>
                            </nuxt-link>
                            <nuxt-link to="/user-agreement">
                                <li>
                                    <a>User Agreement</a>
                                </li>
                            </nuxt-link>
                        </ul>
                    </div>
                </div>
                <div class="content has-text-centered">
                    <div class="control level-item">
                        <div class="tags">
                            <span class="tag is-dark">Copyright © 2020 Skryit</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- <footer class="footer">
            <div class="container">
                <div class="columns">
                    <div class="column is-centered">
                        <ul>
                            <li><a href="#">Copyright © 2019 Skryit</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>-->
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { ACTIONS } from "../store/actions.types";
const {
    FEED_FETCH_POSTS,
    FEED_FETCH_MORE_POSTS,
    NOTIFICATIONS_FETCH_NOTIFICATIONS,
} = ACTIONS;

import debounce from "lodash.debounce";
import PostComposer from "../components/PostComposer.vue";
import Feed from "../components/Feed.vue";
import Post from "../models/Post";
import scrollMixin from "~/mixins/scrollMixin.js";

export default {
    auth: false,
    mixins: [scrollMixin],
    components: { PostComposer, Feed },
    async fetch({ $auth, store }) {
        if ($auth.loggedIn) {
            await store.dispatch(FEED_FETCH_POSTS);
        }
    },
    created() {
        this.debounceFetchMorePosts = debounce(this.fetchMorePosts, 500, {
            leading: true,
        });
    },
    async mounted() {
        if (this.$auth.loggedIn) {
            this.fetchNotifications();
            window.OneSignal.push(async function() {
                const isPushSupported = await window.OneSignal.isPushNotificationsSupported();
                const isPushEnabled = await window.OneSignal.isPushNotificationsEnabled();
                if (isPushSupported && !isPushEnabled) {
                    await window.OneSignal.showSlidedownPrompt({
                        force: $nuxt.context.isDev,
                    });
                }
            });
        }
    },
    computed: {
        postsLoading() {
            return this.$store.state.feed.isLoading;
        },
        next() {
            return Post.getters("next");
        },
    },
    methods: {
        ...mapActions({
            fetchMorePosts: FEED_FETCH_MORE_POSTS,
            fetchNotifications: NOTIFICATIONS_FETCH_NOTIFICATIONS,
        }),
    },
    watch: {
        async isBottom(bottom) {
            if (bottom) {
                await this.debounceFetchMorePosts();
                this.resetIsBottom();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.element {
    &.is-loading {
        position: relative;
        pointer-events: none;
        opacity: 0.5;
        &:after {
            position: absolute;
            top: calc(50% - 2.5em);
            left: calc(50% - 2.5em);
            width: 5em;
            height: 5em;
            border-width: 0.25em;
        }
    }
}

.hero-body {
    height: 70vh;
}
a {
    text-decoration: none;
}

.card-content {
    height: 26vh;
}
</style>
